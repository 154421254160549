export const projectsList = [
  {
    name: 'Sistem Manajemen User Hotspot Berbasis PHP',
    date: '2017',
    slug: 'simuh',
    tagline: 'Proyek skripsi kelulusan',
    url: 'https://github.com/kalingga/skripsi',
    highlight: true,
  },
  {
    name: 'WP Weton',
    date: '2020',
    slug: 'weton',
    tagline: 'Untuk mencari pasaran dan neptu sesuai dengan hitungan Jawa',
    highlight: true,
  },
  {
    name: 'Raffler Undian',
    date: '2018',
    tagline: 'Sistem raffler menggunakan PHP dan MySQL untuk undian',
    url: 'https://github.com/kalingga/undian',
    highlight: true,
  },
  {
    name: 'WP Ngarab',
    date: '2014',
    tagline: 'Untuk mempercantik tulisan huruf arab di wordpress',
    url: 'https://github.com/kalingga/arabic',
    highlight: true,
  },
]
